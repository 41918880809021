<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex align-self-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Discarded Assets</span>
          <v-spacer></v-spacer>

          <v-flex xs12 align-self-center py-2>
            <v-layout wrap justify-end>
              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      label="From Date"
                      outlined
                      class="rounded-xl"
                      hide-details
                      dense
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="fromDate"
                    no-title
                    @input="menu11 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      label="To Date"
                      outlined
                      hide-details
                      dense
                      class="rounded-xl"
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="toDate"
                    no-title
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="assetId"
                  :items="assetList"
                  outlined
                  item-text="name"
                  item-value="_id"
                  dense
                  hide-details
                  class="rounded-xl"
                  label="Assets"
                  clearable
                >
                </v-select>
              </v-flex>

              <v-flex align-self-center xl3 lg3 md3 pb-2 sm3 xs12 px-2>
                <v-select
                  v-model="FassignType"
                  :items="assignTypes"
                  outlined
                  dense
                  hide-details
                  clearable
                  class="rounded-xl"
                  label="Assign Type"
                >
                </v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center v-if="inventoryList.length > 0">
          <v-flex xs12 align-self-center>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Date <span style="color: #ffffff">___________</span>
                    </th>
                    <th class="text-left">Name</th> 
                    <th class="text-left">Asset Tag</th>
                    <th class="text-left">Assign Type</th>
                    <th class="text-left">Department</th>
                    <th class="text-left">Assigned To</th>
                     
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in inventoryList"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.create_date"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.create_date.slice(0, 10) }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.assetId.name"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <router-link
                          :to="
                            '/Assets/ViewLog?id=' +
                            item.assetId._id +
                            '&name=' +
                            item.assetId.name + '&invid=' +
                            item._id
                          "
                        >
                          <span class="kumbhBold" style="color:#005f32">
                            {{ item.assetId.name }}
                          </span>
                        </router-link>
                      </span>
                    </td>
                     
                    <td>
                      <span
                        v-if="item.assetTag"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.assetTag }}</span
                      >
                      <span v-else> 0 </span>
                    </td>

                    <td>
                      <span
                        v-if="item.assignType"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.assignType }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.assignType"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <span v-if="item.departmentId">
                          {{ item.departmentId.deptName }}
                        </span>

                        <span v-else> NA </span>
                      </span>
                    </td>
                    <td>
                     
     
                      <span
                        v-if="item.assignType"
                        class=""
                        style="font-family: kumbhMedium"
                      >
                        <span v-if="item.assignType == 'Department'">
                          <span v-if="item.departmentId">
                            {{ item.departmentId.deptName }}
                          </span>
                        </span>
                        <span v-else-if="item.assignType == 'Employee'">
                          <span v-if="item.employeeId">
                            {{ item.employeeId.name }}
                          </span>
                        </span>
                        <span v-else>
                          {{ item.assignType }}
                        </span>
                      </span>
                    </td>
                    
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        <v-layout wrap v-else>
          <v-flex xs12 align-self-center pa-16>
            <span class="kumbhRegular">No Items found!</span>
          </v-flex>
        </v-layout>

         
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentPage"
            color="#005f32"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,
      g: false,
      totalData: 0,
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      inventoryList: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      name: null,
      assetId: null,
      assetList: [],
      date: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      assignTypes: ["Not Assigned", "Department", "Employee"],
      assignType: null,
      FassignType: null,
      assignList: ["Department", "Employee"],
      employeeId: null,
      departmentId: null,
      search1: null,

      empList: [],
      deptList: [],
      quantity: null,
      dismantleQuantity: null,
      prevQuantity: null,
      toDismantle: false,
    };
  },
  components: {
    // ImageComp,
  },
  watch: {
    fromDate() {
      if (this.currentPage > 1) this.currentPage = 1;

      this.getData();
    },
    toDate() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    assetId() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    FassignType() {
      if (this.currentPage > 1) this.currentPage = 1;
      this.getData();
    },
    
    currentPage() {
      this.getData()
    }
     
  },
  mounted() { 
    if (this.fromDate) {
      this.fromDate = null;
    }
    if (this.toDate) {
      this.toDate = null;
    }
    this.getData();
    this.getAssets();
  },

  methods: {
    getAssets() {
      this.appLoading = true;
      axios({
        url: "/assets/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assetList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    

    getData() {
      this.appLoading = true;
      axios({
        url: "/asset/inventoryStocks/dismantle/list",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          assetId: this.assetId,
          assignType: this.FassignType,

          page: this.currentPage,
          limit: 20,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.inventoryList = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
     
  },
};
</script>
